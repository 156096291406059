<template>
  <v-container fluid fill-height>
    <v-layout align-center justify-center>
      <div class="chooseNumber">
        <v-progress-circular
          :rotate="270"
          :size="250"
          :width="10"
          :value="bar"
          :aria-valuemax="10"
          :max="10"
          color="primary"
          class="numberCircle"
        >
          {{ value }}
        </v-progress-circular>
        <div class="changeNumberBtn">
          <v-btn
            @click="changeNumber(0)"
            fab
            color="primary"
            class="ma-3"
            :disabled="value < 1"
          >
            <v-icon dark>mdi-minus-circle-multiple-outline</v-icon>
          </v-btn>
          <v-btn
            @click="changeNumber(1)"
            fab
            color="primary"
            class="ma-3"
            :disabled="value < 0.1"
          >
            <v-icon dark>mdi-minus-circle-outline</v-icon>
          </v-btn>
          <v-btn
            @click="changeNumber(2)"
            fab
            color="primary"
            class="ma-3"
            :disabled="value > 9.9"
          >
            <v-icon dark>mdi-plus-circle-outline</v-icon>
          </v-btn>
          <v-btn
            @click="changeNumber(3)"
            fab
            color="primary"
            class="ma-3"
            :disabled="value > 9"
          >
            <v-icon dark>mdi-plus-circle-multiple-outline</v-icon>
          </v-btn>
        </div>

        <v-dialog transition="dialog-bottom-transition" max-width="600">
          <template v-slot:activator="{ on, attrs }">
            <div class="saveBtn">
              <v-btn fab color="primary" class="ma-3" v-bind="attrs" v-on="on">
                <v-icon dark>mdi-content-save-all</v-icon>
              </v-btn>
            </div>
          </template>
          <template v-slot:default="dialog">
            <v-card>
              <v-toolbar color="primary" dark
                >Are you sure you want to save:</v-toolbar
              >
              <v-card-text>
                <div class="text-h2 pt-10">{{ value }}</div>
              </v-card-text>
              <v-card-actions class="justify-end">
                <v-btn text @click="(dialog.value = false), saveNumber()"
                  >Confirm</v-btn
                >
                <v-btn text @click="dialog.value = false">Cancel</v-btn>
              </v-card-actions>
            </v-card>
          </template>
        </v-dialog>
      </div>
    </v-layout>
  </v-container>
</template>

<script>
import firebase from "firebase";
import "firebase/firestore";
import moment from "moment";

export default {
  data: () => ({
    value: 0,
    bar: 0,
  }),

  methods: {
    saveNumber() {
      let UserID = firebase.auth().currentUser.uid;
      this.$router.push("/home");
      const current = new Date();
      var time = moment(current).format("HH:mm:ss");

      //TODO: use moment
      const date = `${current.getFullYear()}/${
        current.getMonth() + 1
      }/${current.getDate()}`;

      firebase
        .firestore()
        .collection("users")
        .doc(UserID + "/data/" + date)
        .set({ number: this.value, date: date, time: time }, { merge: true });
    },
    changeNumber(type) {
      let number = this.value;
      switch (type) {
        case 0:
          this.value = (number * 10 - 1 * 10) / 10;
          this.bar = this.value * 10;
          break;
        case 1:
          this.value = (number * 10 - 0.1 * 10) / 10;
          this.bar = this.value * 10;
          break;
        case 2:
          this.value = (number * 10 + 0.1 * 10) / 10;
          this.bar = this.value * 10;
          break;
        case 3:
          this.value = (number * 10 + 1 * 10) / 10;
          this.bar = this.value * 10;
          break;
        default:
          break;
      }
    },
  },
};
</script>

<style>
.numberCircle {
  display: flex;
  margin: 0 auto !important;
}
.chooseNumber {
  margin-top: 20px;
  display: block;
}
.changeNumberBtn {
  display: flex;
  justify-content: center;
}
.saveBtn {
  display: flex;
  justify-content: center;
}
</style>
